
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'OndutyAnalysisRealtime'
})
@KeepAlive
export default class OndutyAnalysisRealtime extends Vue {
  searchInfo = {
    projectName: '',
    workerName: '',
    deviceStatus: '',
    presentStatus: '',
    isDevice: '',
    arrangeStatus: ''
  }

  tableData: any = []
  total = 0
  page = 1
  size = 10
  loading = false

  created () {
    console.log('初始化实时')
    this.loadData()
  }

  // 搜索
  onSearch () {
    this.page = 1
    this.loadData()
  }

  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.worker.exportWorkerOnWork, this.searchInfo).finally(() => {
      loading.close()
    })
  }

  // 获取列表数据
  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.worker.selectWorkerOnWorkjPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total
      this.tableData = res.list
    }).finally(() => {
      this.loading = false
    })
  }
}
